import React from 'react';

function NotFound() {
  return (
    <div className='text-center '>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <h1>404 - Not Found</h1>
        <p>Sorry, the page you're looking for does not exist.</p>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>

    </div>
  );
}

export default NotFound;